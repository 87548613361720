/**
 * Utility functions for handling portal configurations.
 */

import { Observable, switchMap, take } from 'rxjs';
import { map } from 'rxjs/operators';

import { PageConfiguration, PageType, PortalConfiguration } from '@celum/portals/domain';

import { ConfigurationService } from '../model/configuration.service';

export type PageWithIndex = { page: PageConfiguration; pageIndex: number };

/**
 * Retrieves all pages of given types or all pages if no types are provided.
 *
 * @param configurationService - the configuration service
 * @param types - the types of pages to retrieve.
 * @returns an observable emitting an array of page configurations.
 */
export function getPages(configurationService: ConfigurationService, types?: PageType[]): Observable<PageConfiguration[]> {
  return configurationService.portalConfiguration$.pipe(
    map(configuration =>
      types?.length
        ? // TODO SPOR-1580 remove the inner ternary operation and simply use types.includes(page.pageType)
          configuration.configuration.pages.filter(page =>
            types.length === 1 && types[0] === PageType.PAGE ? page.pageType !== PageType.FALLBACK : types.includes(page.pageType)
          )
        : configuration.configuration.pages
    )
  );
}

/**
 * Retrieves 'FALLBACK' page.
 *
 * @param configurationService - the configuration service
 * @returns an observable emitting the fallback page.
 */
export function getFallbackPage(configurationService: ConfigurationService): Observable<PageConfiguration> {
  return getPages(configurationService, [PageType.FALLBACK]).pipe(map(pages => pages[0]));
}

/**
 * Retrieves the active page along with its index.
 *
 * @param configurationService - the configuration service
 * @returns an observable with the active page and its index.
 */
export function getActivePageWithIndex(configurationService: ConfigurationService): Observable<PageWithIndex> {
  return configurationService.activePage$.pipe(
    take(1),
    switchMap(page => getPages(configurationService).pipe(map(pages => ({ page, pageIndex: pages.findIndex(pageConf => pageConf.id === page.id) })))),
    take(1)
  );
}

/**
 * Retrieves an index of a page ( in a list of pages with only PAGE type ) based on the provided page ID or index.
 *
 * @param configurationService - the configuration service
 * @param pageId - the ID of the page to retrieve.
 * @returns an observable with the active page and its index.
 */
export function getPageIndexInPageTypesArray(configurationService: ConfigurationService, pageId: string): Observable<number> {
  return getPages(configurationService, [PageType.PAGE]).pipe(
    take(1),
    map(pages => pages.findIndex(pageConfig => pageConfig.id === pageId))
  );
}

/**
 * Retrieves a page configuration from the portal configuration based on the provided page ID.
 *
 * @param portalConfiguration - the portal configuration
 * @param pageId - the ID of the page to retrieve.
 * @returns the page configuration
 */
export function getPageFromPortalConfiguration(portalConfiguration: PortalConfiguration, pageId: string): PageConfiguration {
  return portalConfiguration?.configuration.pages.find(page => page.id === pageId);
}
