import { License } from './license.model';

export enum UserState {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DELETED = 'DELETED'
}

export enum ExperiencePermissions {
  FULL_ACCESS = 'FullAccess'
}

export enum WorkroomPrivilege {
  CURATOR = 'Curating',
  PARTICIPANT = 'Participating'
}

export enum ExperiencePrivilege {
  FULL_ACCESS = 'FullAccess',
  NONE = 'None'
}

export enum AccountUserRole {
  MEMBER = 'MEMBER',
  MANAGER = 'MANAGER'
}

export enum InvitationStatus {
  /** For email based invitation. The user has to accept the invitation to become a member. */
  INVITED = 'INVITED',
  /** The user accepted a personal (email based) invitation. */
  ACCEPTED = 'ACCEPTED',
  /** The user rejected a personal (email based) invitation. */
  REJECTED = 'REJECTED',
  /** For shared link based invitation requests. One of the account managers has to approve the invitation for the user to become a member. */
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  /** An account manager approved the link based invitation request. */
  APPROVED = 'APPROVED',
  /** An account manager rejected the link based invitation request. */
  DISAPPROVED = 'DISAPPROVED'
}

export enum AccountRequestStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  DISAPPROVED = 'DISAPPROVED'
}

export enum AccountAccessStatus {
  /** Account/User is not yet fully initialized (invitation or access request is pending or denied). */
  INIT = 'INIT',
  /** Account/User is has been initialized and active. */
  ACTIVE = 'ACTIVE',
  /** Account/User has been disabled. */
  INACTIVE = 'INACTIVE'
}

export interface CHRepository {
  repositoryId: string;
  url: string;
}

export interface UserInvitation {
  id: string;
  accountId: string;
  invitationStatus: InvitationStatus;
  lastInvitationStatusChange: Date;
  inviterFirstName: string;
  inviterLastName: string;
  inviterEmail: string;
  reason: string;
  accountName: string;
  accountLogoDownloadLink: string;
  accountAccessToken: string;
}

export interface TrialPlan {
  accountRequestId: string;
  accountRequestOrgName: string;
  status: AccountRequestStatus;
}

export interface AccountAccess {
  accountId: string;
  accountName: string;
  status: AccountAccessStatus;
  accountAccessToken: string;
  licenses: License[];
  ownerOid: string;
  ownerName: string;
  ownerEmail: string;
  profilePictureDownloadLink: string;
  storageLimit: number;
  role: AccountUserRole;
  accountLogoDownloadLink: string;
  ownerPhoneNumber?: string;
  discriminator: 'account-access';
  experiencePermissions: ExperiencePermissions[];
  privileges: {
    work: WorkroomPrivilege | null;
    experience: ExperiencePrivilege | null;
  };
}

export enum ProvisioningType {
  AUTOMATIC = 'AUTOMATIC',
  MANUAL = 'MANUAL'
}

export interface UserDetails {
  oid: string;
  firstName: string;
  lastName: string;
  email: string;
  accountAccesses: AccountAccess[];
  profilePictureDownloadLink: string;
  status: UserState;
  locale: string;
  admin: boolean;
  invitations: UserInvitation[];
  trialPlan: TrialPlan;
  provisioningType: ProvisioningType;
}
