import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

import { GeneralSettings, PageConfiguration, PortalConfiguration, WidgetConfigMap, WidgetIdentifier } from '@celum/portals/domain';

import { ConfigurationResolver } from './configuration-resolver';
import { PortalNavigationService } from './portal-navigation.service';
import { ConfigurationService } from '../model/configuration.service';
import { getPageFromPortalConfiguration } from '../util/configuration.util';

@Injectable()
export class ReadonlyConfigurationService implements ConfigurationService {
  public generalSettings$ = new BehaviorSubject<GeneralSettings>(null);
  public portalConfiguration$ = new BehaviorSubject<PortalConfiguration>(null);
  public widgets$ = new ReplaySubject<WidgetIdentifier[]>(1);
  public widgetConfigs$ = new BehaviorSubject<WidgetConfigMap>(null);
  public portalId$ = new BehaviorSubject<string>(null);
  public activePageId$ = new BehaviorSubject<string>(null);
  public activePage$ = new BehaviorSubject<PageConfiguration>(null);

  constructor(configurationResolver: ConfigurationResolver, navigationService: PortalNavigationService) {
    const config = configurationResolver.configuration$.getValue();

    this.portalId$.next(config.id);
    this.portalConfiguration$.next(config);
    this.generalSettings$.next(config.configuration.general.settings);
    this.widgetConfigs$.next(config.configuration.widgetMap);

    navigationService.activePageId$.subscribe(activePageId => {
      const portalConfiguration = this.portalConfiguration$.getValue();

      const activePageConfiguration = getPageFromPortalConfiguration(portalConfiguration, activePageId);

      this.activePageId$.next(activePageConfiguration.id);
      this.activePage$.next(activePageConfiguration);
      this.widgets$.next([
        ...(portalConfiguration.configuration.general.widgets.header ? [portalConfiguration.configuration.general.widgets.header] : []),
        ...activePageConfiguration.widgets
      ]);
    });
  }

  public selectWidget<C>(widgetId: string, params?: C): void {
    console.warn('You are not in edit mode! "selectWidget" does nothing in readonly mode!');
  }

  public clearSelectedWidget(): void {
    console.warn('You are not in edit mode! "clearSelectedWidget" does nothing in readonly mode!');
  }

  public getPortalId(): string {
    return this.portalId$.value;
  }
}
